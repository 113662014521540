/* eslint no-template-curly-in-string: 0*/
const awsConfig = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://jievqcbok2.execute-api.eu-central-1.amazonaws.com/prod/v1",
  },
  gqlGateway: {
    REGION: "eu-central-1",
    URL: "https://jievqcbok2.execute-api.eu-central-1.amazonaws.com/prod/graphql",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_yeZkjC97N",
    APP_CLIENT_ID: "7q5lon92e3ocsombfn8p26g9sl",
    IDENTITY_POOL_ID: "eu-central-1:63920de1-976b-4c6f-95ba-3b7fffc15ad4",
  },
  i18n: {
    host: "locales.digital.prod.canei.io",
  },
  assets: {
    host: "assets.digital.prod.canei.io",
  },
  payment: {
    publicKey: "pk_live_51Kb2CFJafEP8n6QGCsyRTLS2FsUkg2gdeLl5xjN4NI4la61T2lKAoFVYw2vLCAHISyxcMn7hG23aEXF1PwUuNIFu00jnz4w2D7",
  },
  registration: {
    FORM_POST: "https://support.digital.prod.canei.io/v1/forms",
    NEXT_PAGE: "https://app.digital.prod.canei.io",
    TO_REFERRER: "https://canei.ag/register-success/",
  },
};

export default awsConfig;
